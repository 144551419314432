<template>
    <modal-lateral ref="modalFiltro" titulo="Filtro">
        <div class="custom-scroll overflow-auto pb-2" style="height:calc(100vh - 101px);">
            <div class="row mx-0 align-items-end">
                <div class="col">
                    <p class="label-mini">Valor</p>
                    <money v-model="valor_desde" class="input-money w-100" size="small" v-bind="money" />
                </div>
                <div class="col">
                    <money v-model="valor_hasta" class="input-money w-100" size="small" v-bind="money" />
                </div>
            </div>
            <div class="row mx-0 align-items-end mt-3">
                <div class="col">
                    <p class="label-mini">No. de productos</p>
                    <el-input v-model="productos_desde" placeholder="Desde" class="w-100" size="small" @change="soloNumeros('productos_desde')" />
                </div>
                <div class="col">
                    <el-input v-model="productos_hasta" placeholder="Hasta" class="w-100" size="small" @change="soloNumeros('productos_hasta')" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <p class="label-mini">Leecheros</p>
                    <el-select v-model="leechero" placeholder="Seleccione" class="w-100" size="small">
                        <el-option
                        v-for="item in filtros.leecheros"
                        :key="item.id"
                        :label="item.propietario_nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <p class="label-mini">Conductor</p>
                    <el-select v-model="conductor" placeholder="Seleccione" class="w-100" size="small">
                        <el-option
                        v-for="item in filtros.info_vehiculo"
                        :key="item.id"
                        :label="item.conductor"
                        :value="item.conductor"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <p class="label-mini">Vehículo</p>
                    <el-select v-model="vehiculo" placeholder="Seleccione" class="w-100" size="small">
                        <el-option
                        v-for="item in filtros.info_vehiculo"
                        :key="item.id"
                        :label="item.vehiculo"
                        :value="item.vehiculo"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <p class="label-mini">Estado leeche</p>
                    <el-select v-model="estado_leeche" placeholder="Seleccione" class="w-100" size="small">
                        <el-option
                        v-for="item in filtros.estados_leeche"
                        :key="item.id"
                        :label="item.estado"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <p class="label-mini">Estado operador logístico</p>
                    <el-select v-model="estado_logistico" placeholder="Seleccione" class="w-100" size="small">
                        <el-option
                        v-for="item in filtros.estado_logistico"
                        :key="item.id"
                        :label="item.estado_logistico"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 align-items-end mt-3">
                <div class="col">
                    <p class="label-mini">Peso</p>
                    <el-input v-model="peso_desde" placeholder="Desde" class="w-100" size="small" @change="soloNumeros('peso_desde')" />
                </div>
                <div class="col">
                    <el-input v-model="peso_hasta" placeholder="Hasta" class="w-100" size="small" @change="soloNumeros('peso_hasta')" />
                </div>
            </div>
            <div class="row mx-0 align-items-end mt-3">
                <div class="col">
                    <p class="label-mini">Volumen</p>
                    <el-input v-model="volumen_desde" placeholder="Desde" class="w-100" size="small" @change="soloNumeros('volumen_desde')" />
                </div>
                <div class="col">
                    <el-input v-model="volumen_hasta" placeholder="Hasta" class="w-100" size="small" @change="soloNumeros('volumen_hasta')" />
                </div>
            </div>
        </div>
        <div v-if="$route.name == 'admin.rutas-entrega-2.historial-pedidos'" class="row mx-0 justify-center">
            <div class="btn-general px-5 f-14">
                Buscar pedidos
            </div>
        </div>
        <div v-else class="row mx-0 justify-content-center">
            <div class="btn-outline px-5 f-14 mx-2" @click="irLimpiar">
                Limpiar
            </div>
            <div class="btn-general px-5 f-14 mx-2" @click="irFiltrar">
                Filtrar
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import {Money} from 'v-money'
export default {
    components:{
        Money,
    },
    props: {
        filtros: {
            type: Object, required: true,
        }
    },
    data(){
        return {
            valor_desde: '',
            valor_hasta: '',
            productos_desde: '',
            productos_hasta: '',
            leechero: '',
            conductor: '',
            vehiculo: '',
            estado_leeche: '',
            estado_logistico: '',
            peso_desde: '',
            peso_hasta: '',
            volumen_desde: '',
            volumen_hasta: '',
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },

    methods: {
        toggle(){
            this.$refs.modalFiltro.toggle();
        },

        irFiltrar(){
            const filtros = new Object();
            filtros.valor_desde = this.valor_desde;
            filtros.valor_hasta = this.valor_hasta;
            filtros.productos_desde = this.productos_desde;
            filtros.productos_hasta = this.productos_hasta;
            filtros.leechero = this.leechero;
            filtros.conductor = this.conductor;
            filtros.vehiculo = this.vehiculo;
            filtros.estado_leeche = this.estado_leeche;
            filtros.estado_logistico = this.estado_logistico;
            filtros.peso_desde = this.peso_desde;
            filtros.peso_hasta = this.peso_hasta;
            filtros.volumen_desde = this.volumen_desde;
            filtros.volumen_hasta = this.volumen_hasta;
            this.$emit('filtrar-pedidos',filtros);
            this.toggle();
        },

        irLimpiar(){
            this.valor_desde = '';
            this.valor_hasta = '';
            this.productos_desde = '';
            this.productos_hasta = '';
            this.leechero = '';
            this.conductor = '';
            this.vehiculo = '';
            this.estado_leeche = '';
            this.estado_logistico = '';
            this.peso_desde = '';
            this.peso_hasta = '';
            this.volumen_desde = '';
            this.volumen_hasta = '';
        },

        async soloNumeros(bandera){
            const soloNumeros = /^[0-9]+$/;
            if (bandera === "productos_desde"){
                if (!this.productos_desde.match(soloNumeros)){
                    this.productos_desde = '';
                }
            }
            if (bandera === "productos_hasta"){
                if (!this.productos_hasta.match(soloNumeros)){
                    this.productos_hasta = '';
                }
            }
            if (bandera === "volumen_desde"){
                if (!this.volumen_desde.match(soloNumeros)){
                    this.volumen_desde = '';
                }
            }
            if (bandera === "volumen_hasta"){
                if (!this.volumen_hasta.match(soloNumeros)){
                    this.volumen_hasta = '';
                }
            }
            if (bandera === "peso_desde"){
                if (!this.peso_desde.match(soloNumeros)){
                    this.peso_desde = '';
                }
            }
            if (bandera === "peso_hasta"){
                if (!this.peso_hasta.match(soloNumeros)){
                    this.peso_hasta = '';
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
</style>
